import { FC } from 'react';

import { Head } from '@dxp-next';
import { useContent } from '@sitecore/common';
import { StructuredDataSchemaRendering } from '@sitecore/types/manual/StructuredDataSchema';

const StructuredDataSchema: FC<StructuredDataSchemaRendering> = () => {
  const { fields } = useContent<StructuredDataSchemaRendering>();

  if (!fields?.schemas) return null;

  return (
    <Head>
      <script type="application/ld+json">{JSON.stringify(fields.schemas)}</script>
    </Head>
  );
};

export default StructuredDataSchema;
